import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Romano's Pizzeria
			</title>
			<meta name={"description"} content={"Savor the Authentic Taste of Italy"} />
			<meta property={"og:title"} content={"Home | Romano's Pizzeria"} />
			<meta property={"og:description"} content={"Savor the Authentic Taste of Italy"} />
			<meta property={"og:image"} content={"https://barnislober.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://barnislober.com/img/4443330.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://barnislober.com/img/4443330.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://barnislober.com/img/4443330.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://barnislober.com/img/4443330.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://barnislober.com/img/4443330.png"} />
			<meta name={"msapplication-TileImage"} content={"https://barnislober.com/img/4443330.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(0, 0, 0, 0.7) 0%,rgba(0, 0, 0, 0.6) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://barnislober.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					Romano's Pizzeria
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 100px 0px 100px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Welcome to Romano's Pizzeria, where every bite takes you on a culinary journey to the heart of Italy. Our passion for creating the most authentic and delicious pizzas is matched only by our commitment to providing a warm and inviting atmosphere for our guests. Whether you're here for a quick lunch, a cozy dinner, or a festive gathering, Romano's is the perfect place to relax and enjoy a meal with family and friends.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
					justify-content="center"
					align-items="center"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="--color-primary"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						hover-background="rgba(63, 36, 216, 0)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Contact Us
					</Button>
				</Box>
			</Box>
		</Section>
		<Section
			padding="88px 0 88px 0"
			sm-padding="60px 0 60px 0"
			box-shadow="--m"
			background="--color-light"
			md-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-25"
		>
			<Override slot="SectionContent" max-width="1220px" sm-min-width="280px" />
			<Box
				width="100%"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				margin="0px 0px 64px 0px"
				display="flex"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 28px/1.2 --fontFamily-sans"
					text-align="center"
					md-font="--headline3"
				>
					Our Philosophy
				</Text>
				<Text
					color="--darkL2"
					text-align="center"
					lg-width="100%"
					margin="0px 0px 0px 0px"
					font="--base"
					width="70%"
					lg-max-width="720px"
				>
					Our mission is simple: to bring the authentic flavors of Italy to our community. We believe that great pizza starts with great ingredients, and we source ours from local farmers and producers whenever possible. We are committed to sustainability and strive to minimize our environmental footprint through eco-friendly practices in our kitchen and dining areas. At Romano's, we are not just making pizzas – we are creating memories and traditions that will be cherished for years to come.
				</Text>
				<Text
					margin="80px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 28px/1.2 --fontFamily-sans"
					text-align="center"
					md-font="--headline3"
				>
					Why Choose Romano's Pizzeria?
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="24px"
				lg-grid-gap="16px"
				md-grid-template-columns="1fr"
			>
				<Box
					display="flex"
					padding="46px 26px 47px 26px"
					background="#FFFFFF"
					flex-direction="column"
					justify-content="flex-start"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase" text-align="center">
						Authentic Recipes: 
						</Strong>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Our pizzas are crafted using traditional Italian recipes passed down through generations.
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 26px 47px 26px"
					background="#FFFFFF"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase" text-align="center">
						Fresh Ingredients: 
						</Strong>
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						text-align="center"
					>
						We use only the freshest ingredients, sourced locally to ensure the highest quality.
					</Text>
				</Box>
				<Box
					display="flex"
					background="#FFFFFF"
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 26px 47px 26px"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--lead" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase">
						Cozy Atmosphere: 
						</Strong>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Our pizzeria is designed to provide a warm and welcoming environment for all our guests.
					</Text>
				</Box>
				<Box
					display="flex"
					background="#FFFFFF"
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 26px 47px 26px"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--lead" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase">
						Skilled Chefs: 
						</Strong>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Our chefs are passionate about pizza and have years of experience in creating the perfect pie.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});